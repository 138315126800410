import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './App.css';

import ConfirmationForm from './Forms/ConfirmationForm';
import ApplicationForm from './Forms/ApplicationForm';
import AdminForm from './Forms/AdminForm';

function App() {
  return (
    <Router>
      <div id="page">
        <div className="container pb-3">
          <div id="logo-img" className="mx-auto py-3">
            <img src="/trclogo.png" alt="TRC Logo" className="img-fluid" />
          </div>
          <Switch>
            <Route exact path="/">
              <Links />
            </Route>
            <Route path="/newmember">
              <ApplicationForm />
            </Route>
            <Route path="/confirmation">
              <ConfirmationForm />
            </Route>
            <Route path="/setup">
              <AdminForm />
            </Route>
            <Route path="/success">
              <div>
                <p className="text-center h2">Your Application was Successfully Recorded</p>
              </div>
            </Route>
            <Route path="*">
              <div>
                <p className="text-center h2">404 - Not Found</p>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

function Links() {
  return (
    <div id="links">
      <div className="row">
        <div className="mx-auto col-md-4 mb-3">
          <div className="card shadow-lg">
            <div className="card-body">
              <h5 className="card-title">Apply for Membership</h5>
              <h6 className="card-subtitle mb-2 text-muted">For new members</h6>
              <Link to="/newmember">
                <button className="btn btn-primary">
                  Go&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
