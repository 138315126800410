/*
*
*   Form displayed to the public and used for creating the initial application.
*   On submission, data is sent to the 'Raw Form Data' sheet.
*
*/

import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Markdown from 'react-markdown';

// Yup schema used for client-side validation of form inputs
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("A first name is required"),
    lastName: Yup.string().required("A last name is required"),
    dateOfBirth: Yup.date().required("A date of birth is required").max(new Date(), "A date of birth cannot be in the future"),
    gender: Yup.string().required("A gender is required"),
    phone: Yup.string().required("A phone number is required"),
    email: Yup.string().required("An email is required").email("Please check your email"),
    parentName: Yup.string().when("dateOfBirth", (dob, schema) => {
        // Sets parentName to required only if applicant is under 18
        if (isUnder18(dob)) { return schema.required("A parent name is required"); }
        return schema;
    }),
    parentPhone: Yup.string().when("dateOfBirth", (dob, schema) => {
        // Sets parentPhone to required only if applicant is under 18
        if (isUnder18(dob)) { return schema.required("A parent phone is required"); }
        return schema;
    }),
    parentEmail: Yup.string().when("dateOfBirth", (dob, schema) => {
        // Sets parentEmail to required only if applicant is under 18
        if (isUnder18(dob)) { return schema.required("A parent email is required").email("Please check your email"); }
        return schema;
    }),
    streetAddress: Yup.string().required("A street address is required"),
    suburb: Yup.string().required("A suburb is required"),
    postCode: Yup.string().required("A post code is required").matches(/\d{4}/, "Please check your post code"),
    postalStreetAddress: Yup.string().when("postalIsBilling", (pib, schema) => {
        // Sets postalStreetAddress to required only if the postal address is not the billing address
        if (!pib) { return schema.required("A postal address is required"); }
        return schema;
    }),
    postalSuburb: Yup.string().when("postalIsBilling", (pib, schema) => {
        // Sets postalSuburb to required only if the postal address is not the billing address
        if (!pib) { return schema.required("A postal suburb is required"); }
        return schema;
    }),
    postalPostCode: Yup.string().when("postalIsBilling", (pib, schema) => {
        // Sets postalPostCode to required only if the postal address is not the billing address
        if (!pib) { return schema.required("A postal post code is required").matches(/\d{4}/, "Please check your post code"); }
        return schema;
    }),
    medicalIssues: Yup.string().required("Please specify"),
    medicalDetails: Yup.string().when("medicalIssues", (medIss, schema) => {
        // Sets medicalDetails to required only if the applicant specifies that they have medical issues
        if (medIss === "yes") { return schema.required("Please specify any relevent medical issues"); }
        return schema;
    }),
    emergContactName: Yup.string().required("An emergency contact name is required"),
    emergContactPhone: Yup.string().required("An emergency contact phone number is required"),
    membershipType: Yup.string().required("A membership type is required")
})

// Given a date of birth, returns a boolean indicating if the applicant is under 18
function isUnder18(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    const age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        return age - 1 < 18;
    }

    return age < 18;
}

function ApplicationForm() {

    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [header, setHeader] = useState('');
    const [footer, setFooter] = useState('');

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const data = await axios.get('/api/formdata');

        setTitle(data.data.title);
        setHeader(data.data.header);
        setFooter(data.data.footer);
        setLoading(false);
    }

    return (
        <Fragment>
            {loading && (
                <div>
                    <p className="text-center h2">Loading...</p>
                </div>
            )}

            {!loading && (
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h2 className="text-center">{title}</h2>
                        <Markdown>{header}</Markdown>
                        <hr />

                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                dateOfBirth: '',
                                gender: '',
                                phone: '',
                                email: '',
                                parentName: '',
                                parentPhone: '',
                                parentEmail: '',
                                streetAddress: '',
                                suburb: '',
                                postalIsBilling: true,
                                postCode: '',
                                postalStreetAddress: '',
                                postalSuburb: '',
                                postalPostCode: '',
                                medicalIssues: '',
                                medicalDetails: '',
                                emergContactName: '',
                                emergContactPhone: '',
                                membershipType: '',
                                rowingHistory: '',
                                canSwim: false,
                                newsletterSub: true,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                try {
                                    const { data } = await axios.post("/api/newmember", values);
                                    setSubmitting(false);
                                    if (data.status && data.status === "success") {
                                        history.push("/success");
                                    } else if (!data.status || data.status === "error") {
                                        alert("An error has occured. Please try again");
                                    }
                                } catch (err) {
                                    setSubmitting(false);
                                    console.log(err);
                                    alert(`An error has occured (${err.message})`);
                                }
                            }}
                        >
                            {({ isSubmitting, values, errors, touched, isValid, submitCount, isValidating }) => (
                                <Form>
                                    <p className="h4">Personal Details</p>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`}
                                                    id="firstName"
                                                    name="firstName"
                                                />
                                                <ErrorMessage name="firstName">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`}
                                                    id="lastName"
                                                    name="lastName"
                                                />
                                                <ErrorMessage name="lastName">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="dateOfBirth">Date of Birth</label>
                                                <Field
                                                    type="date"
                                                    className={`form-control ${errors.dateOfBirth && touched.dateOfBirth ? 'is-invalid' : ''}`}
                                                    id="dateOfBirth"
                                                    name="dateOfBirth"
                                                />
                                                <ErrorMessage name="dateOfBirth">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <label>Gender</label><br />
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className={`form-check-input ${errors.gender && touched.gender ? 'is-invalid' : ''}`}
                                                    type="radio"
                                                    name="gender"
                                                    id="genderMale"
                                                    value="M"
                                                />
                                                <label className="form-check-label" htmlFor="genderMale">Male</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className={`form-check-input ${errors.gender && touched.gender ? 'is-invalid' : ''}`}
                                                    type="radio"
                                                    name="gender"
                                                    id="genderFemale"
                                                    value="F"
                                                />
                                                <label className="form-check-label" htmlFor="genderFemale">Female</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className={`form-check-input ${errors.gender && touched.gender ? 'is-invalid' : ''}`}
                                                    type="radio"
                                                    name="gender"
                                                    id="genderReserved"
                                                    value="-"
                                                />
                                                <label className="form-check-label" htmlFor="genderReserved">Rather not say</label>
                                            </div>
                                            <ErrorMessage name="gender">
                                                {msg => (<small className="form-text text-danger">{msg}</small>)}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <p className="h4 mt-4">Contact Details</p>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="phone">Phone</label>
                                                <Field
                                                    type="tel"
                                                    className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                                                    id="phone"
                                                    name="phone"
                                                />
                                                <ErrorMessage name="phone">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    type="email"
                                                    className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    name="email"
                                                />
                                                <ErrorMessage name="email">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Ask for parent/guardian details only if the applicant is under 18 */}
                                    {isUnder18(values.dateOfBirth) && (
                                        <div className="form-row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="parentName">Parent/Guardian Name</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${errors.parentName && touched.parentName ? 'is-invalid' : ''}`}
                                                        id="parentName"
                                                        name="parentName"
                                                    />
                                                    <ErrorMessage name="parentName">
                                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="parentPhone">Parent/Guardian Phone</label>
                                                    <Field
                                                        type="tel"
                                                        className={`form-control ${errors.parentPhone && touched.parentPhone ? 'is-invalid' : ''}`}
                                                        id="parentPhone"
                                                        name="parentPhone"
                                                    />
                                                    <ErrorMessage name="parentPhone">
                                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="parentEmail">Parent/Guardian Email</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${errors.parentEmail && touched.parentEmail ? 'is-invalid' : ''}`}
                                                        id="parentEmail"
                                                        name="parentEmail"
                                                    />
                                                    <ErrorMessage name="parentEmail">
                                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="streetAddress">Street Address</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.streetAddress && touched.streetAddress ? 'is-invalid' : ''}`}
                                                    id="streetAddress"
                                                    name="streetAddress"
                                                />
                                                <ErrorMessage name="streetAddress">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="suburb">Suburb</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.suburb && touched.suburb ? 'is-invalid' : ''}`}
                                                    id="suburb"
                                                    name="suburb"
                                                />
                                                <ErrorMessage name="suburb">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="postCode">Post Code</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.postCode && touched.postCode ? 'is-invalid' : ''}`}
                                                    id="postCode"
                                                    name="postCode"
                                                />
                                                <ErrorMessage name="postCode">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group form-check">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="postalIsBilling"
                                                    name="postalIsBilling"
                                                />
                                                <label className="form-check-label" htmlFor="postalIsBilling">Postal Address is the same as Billing Address</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Ask for postal address only if the postal address is different to the billing address */}
                                    {!values.postalIsBilling && (
                                        <>
                                            <p className="h4 mt-4">Postal Address</p>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="postalStreetAddress">Street Address/PO Box</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${errors.postalStreetAddress && touched.postalStreetAddress ? 'is-invalid' : ''}`}
                                                            id="postalStreetAddress"
                                                            name="postalStreetAddress"
                                                        />
                                                        <ErrorMessage name="postalStreetAddress">
                                                            {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="postalSuburb">Suburb</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${errors.postalSuburb && touched.postalSuburb ? 'is-invalid' : ''}`}
                                                            id="postalSuburb"
                                                            name="postalSuburb"
                                                        />
                                                        <ErrorMessage name="postalSuburb">
                                                            {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="postalPostCode">Post Code</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${errors.postalPostCode && touched.postalPostCode ? 'is-invalid' : ''}`}
                                                            id="postalPostCode"
                                                            name="postalPostCode"
                                                        />
                                                        <ErrorMessage name="postalPostCode">
                                                            {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <p className="h4 mt-4">Medical Details</p>
                                    <label htmlFor="medicalIssues">Do you have any <strong>medical</strong> issues that may affect your ability to row?</label>
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <Field
                                                    as="select"
                                                    className={`custom-select ${errors.medicalIssues && touched.medicalIssues ? 'is-invalid' : ''}`}
                                                    id="medicalIssues"
                                                    name="medicalIssues"
                                                >
                                                    <option hidden>Please Select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Field>
                                                <ErrorMessage name="medicalIssues">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Ask for medical details only if the applicant specifies that there are medical issues */}
                                    <div hidden={values.medicalIssues !== "yes"} className="form-row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="medicalDetails">Please tell us about your medical requirements:</label>
                                                <Field
                                                    as="textarea"
                                                    className={`form-control ${errors.medicalDetails && touched.medicalDetails ? 'is-invalid' : ''}`}
                                                    id="medicalDetails"
                                                    name="medicalDetails"
                                                    rows="4"
                                                    placeholder="This information will be sent to our First Aid Officer only"
                                                />
                                                <ErrorMessage name="medicalDetails">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-1 mt-4">
                                        <span className="h4">Emergency Contact</span><br />
                                    </p>
                                    <div style={{ lineHeight: 1.2 }} className="pb-2"><small className="text-muted"><em>Your nominated contact will only be contacted in case of an emergency</em></small></div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="emergContactName">Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control ${errors.emergContactName && touched.emergContactName ? 'is-invalid' : ''}`}
                                                    id="emergContactName"
                                                    name="emergContactName"
                                                />
                                                <ErrorMessage name="emergContactName">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="emergContactPhone">Phone</label>
                                                <Field
                                                    type="tel"
                                                    className={`form-control ${errors.emergContactPhone && touched.emergContactPhone ? 'is-invalid' : ''}`}
                                                    id="emergContactPhone"
                                                    name="emergContactPhone"
                                                />
                                                <ErrorMessage name="emergContactPhone">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="h4 mt-4">Membership Details</p>
                                    <div className="form-row">
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label htmlFor="membershipType">Membership Type</label>
                                                <Field
                                                    as="select"
                                                    className={`custom-select ${errors.membershipType && touched.membershipType ? 'is-invalid' : ''}`}
                                                    id="membershipType"
                                                    name="membershipType"
                                                >
                                                    <option hidden>Please Select</option>
                                                    <option value="Full (Rowing)">Full</option>
                                                    <option value="Full (Student)">Student</option>
                                                    <option value="Full (Coxswain)">Coxswain</option>
                                                    <option value="Full (Associate)">Associate</option>
                                                    <option value="Full (Associate:Coach)">Coach</option>
                                                    <option value="Provisional">Provisional</option>
                                                    <option value="Affiliate (Non-Rowing)">Affiliate (Non-Rowing)</option>
                                                    <option value="Affiliate (Temporary)">Affiliate (Temporary)</option>
                                                </Field>
                                                <ErrorMessage name="membershipType">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label htmlFor="rowingHistory">Please detail any rowing history:</label>
                                                <Field
                                                    as="textarea"
                                                    className={`form-control ${errors.rowingHistory && touched.rowingHistory ? 'is-invalid' : ''}`}
                                                    id="rowingHistory"
                                                    name="rowingHistory"
                                                    rows="4"
                                                />
                                                <ErrorMessage name="rowingHistory">
                                                    {msg => (<small className="form-text text-danger">{msg}</small>)}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="canSwim"
                                                    name="canSwim"
                                                />
                                                <label className="form-check-label" htmlFor="canSwim">I can swim 50m in light clothing</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-check">
                                                <Field
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="newsletterSub"
                                                    name="newsletterSub"
                                                />
                                                <label className="form-check-label" htmlFor="newsletterSub">I would like to subscribe to the TRC Newsletter</label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md">
                                            <Markdown>{footer}</Markdown>
                                        </div>
                                    </div>
                                    <hr />
                                    {!isSubmitting && !isValidating && !isValid && submitCount > 0 && (
                                        <div className="mb-2">
                                            <small className="text-danger">Please check your entries and try again</small>
                                        </div>
                                    )}
                                    <div className="d-flex align-items-center">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            Submit
                                        </button>
                                        {isSubmitting && (
                                            <div className="spinner-border ml-3" role="status" aria-hidden="true">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default ApplicationForm;
