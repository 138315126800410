/*
*
*   Form used by the membership administrator to add/amend final details before the application is added to the main spreadsheet
*
*/

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { format, addYears, addMonths } from 'date-fns';

// Yup schema used for client-side validation of form inputs
const validationSchema = Yup.object().shape({
    newRenewing: Yup.string().required("A status is required"),
    membershipCategory: Yup.string().required("A membership category is required"),
    mshipEndDate: Yup.date().required("A membership end date is required").min(new Date(), "Membership end date cannot be in the past"),
    trainingGroup: Yup.string().required("A training group is required")
})

// Returns a date object with the next occurrence of June 30
function getDefaultEndDate(provisional = false) {
    const today = new Date();

    if (provisional) {
        return format(addMonths(new Date(), 3), "yyyy-MM-dd");
    } else {
        const endDate = new Date(today.getFullYear(), "5", "30");

        if (today > endDate) {
            return format(addYears(endDate, 1), "yyyy-MM-dd");
        } else {
            return format(endDate, "yyyy-MM-dd");
        }
    }
}

function ConfirmationForm(props) {

    // Range and Membership Category are populated from the URL query string
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();

    return (
        <div className="card shadow-lg">
            <div className="card-body">
                <h2 className="text-center">New Member Confirmation Form</h2>
                <p>
                    To maintain privacy &amp; security, applicants' personal details will not be displayed in this form. If required, these can be viewed on the registration notification email, or in the membership database (ID: {query.get("id")}).
                </p>
                <p>
                    <strong>Committee approval for the applicant to join the club should be received before completing this form.</strong>
                </p>
                <hr />

                <Formik
                    initialValues={{
                        newRenewing: "New",
                        membershipCategory: query.get("membershipCategory"),
                        mshipEndDate: getDefaultEndDate(query.get("membershipCategory") === "Provisional"),
                        trainingGroup: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {

                            // Object keys are the header of the corresponding column in the Master Spreadsheet
                            const reqBody = {
                                "New/Renew": values.newRenewing,
                                "Category": values.membershipCategory,
                                "Membership End": values.mshipEndDate,
                                "Squad": values.trainingGroup,
                                "Applicant Proposer": values.proposer,
                                "Applicant Seconder": values.seconder,
                                "Notes": values.notes,
                                id: query.get("id")
                            };

                            const { data } = await axios.post("/api/preapprove", reqBody);

                            setSubmitting(false);
                            if (data.status && data.status === "success") {
                                history.push("/success");
                            } else if (!data.status || data.status === "error") {
                                alert("An error has occured. Please try again");
                            }

                        } catch (err) {
                            setSubmitting(false);
                            console.log(err);
                            alert(`An error has occured (${err.message})`);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-row">
                                <div className="col-md form-group">
                                    <label htmlFor="newRenewing">New Member/Renewing Member</label>
                                    <Field
                                        as="select"
                                        className={`custom-select ${errors.newRenewing && touched.newRenewing ? 'is-invalid' : ''}`}
                                        id="newRenewing"
                                        name="newRenewing"
                                    >
                                        <option>New</option>
                                        <option>New from LTR</option>
                                        <option>Renewing</option>
                                    </Field>
                                    <ErrorMessage name="newRenewing">
                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group">
                                        <label htmlFor="membershipCategory">Membership Category</label>
                                        <Field
                                            as="select"
                                            className={`custom-select ${errors.membershipCategory && touched.membershipCategory ? 'is-invalid' : ''}`}
                                            id="membershipCategory"
                                            name="membershipCategory"
                                        >
                                            <option hidden>Please Select</option>
                                            <option value="Full (Rowing)">Full</option>
                                            <option value="Full (Student)">Student</option>
                                            <option value="Full (Coxswain)">Coxswain</option>
                                            <option value="Full (Associate)">Associate</option>
                                            <option value="Full (Associate:Coach)">Coach</option>
                                            <option value="Provisional">Provisional</option>
                                            <option value="Affiliate (Non-Rowing)">Affiliate (Non-Rowing)</option>
                                            <option value="Affiliate (Temporary)">Affiliate (Temporary)</option>
                                        </Field>
                                        <ErrorMessage name="membershipCategory">
                                            {msg => (<small className="form-text text-danger">{msg}</small>)}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-md form-group">
                                    <label htmlFor="mshipEndDate">Membership End Date</label>
                                    <Field
                                        type="date"
                                        className={`form-control ${errors.mshipEndDate && touched.mshipEndDate ? 'is-invalid' : ''}`}
                                        id="mshipEndDate"
                                        name="mshipEndDate"
                                        min={format(new Date(), "yyyy-MM-dd")}
                                    />
                                    <ErrorMessage name="mshipEndDate">
                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                    </ErrorMessage>
                                </div>
                                <div className="col-md form-group">
                                    <label htmlFor="trainingGroup">Squad</label>
                                    <Field
                                        as="select"
                                        className={`custom-select ${errors.trainingGroup && touched.trainingGroup ? 'is-invalid' : ''}`}
                                        id="trainingGroup"
                                        name="trainingGroup"
                                    >
                                        <option hidden>Please Select</option>
                                        <option>Youth</option>
                                        <option>Senior</option>
                                        <option>Para</option>
                                        <option>SASI</option>
                                        <option>Masters</option>
                                        <option>Recreational</option>
                                        <option>TOMS</option>
                                        <option>Non-Rowing</option>
                                    </Field>
                                    <ErrorMessage name="trainingGroup">
                                        {msg => (<small className="form-text text-danger">{msg}</small>)}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md form-group">
                                    <label htmlFor="proposer">Applicant Proposer</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="proposer"
                                        name="proposer"
                                    />
                                </div>
                                <div className="col-md form-group">
                                    <label htmlFor="seconder">Applicant Seconder</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="seconder"
                                        name="seconder"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md form-group">
                                    <label htmlFor="notes">Notes</label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="notes"
                                        name="notes"
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex align-items-center">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                    Submit
                                </button>
                                {isSubmitting && (
                                    <div className="spinner-border ml-3" role="status" aria-hidden="true">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </div>
    );
}

export default ConfirmationForm;