/*
*
*   Form used by the membership administrator to customise form display and spreadsheet link
*
*/

import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';

function AdminForm() {

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [initialTitle, setInitialTitle] = useState('');
    const [initialHeader, setInitialHeader] = useState('');
    const [initialFooter, setInitialFooter] = useState('');

    useEffect(() => {
        getInitialValues();
    }, []);

    const getInitialValues = async () => {
        const data = await axios.get('/api/formdata');

        setInitialTitle(data.data.title);
        setInitialHeader(data.data.header);
        setInitialFooter(data.data.footer);
        setLoading(false);
    }

    return (
        <Fragment>
            {loading && (
                <div>
                    <p className="text-center h2">Loading...</p>
                </div>
            )}

            {!loading && (
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h2 className="text-center">Admin Panel</h2>
                        <hr />

                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                authKey: "",
                                title: initialTitle,
                                header: initialHeader,
                                footer: initialFooter
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                try {

                                    const reqBody = {};

                                    if (values.authKey) reqBody.authKey = values.authKey;
                                    if (values.title) reqBody.title = values.title;
                                    if (values.header) reqBody.header = values.header;
                                    if (values.footer) reqBody.footer = values.footer;

                                    const response = await axios.post("/api/formdata", reqBody);

                                    setSubmitting(false);
                                    if (response.status === 200) {
                                        history.push("/success");
                                    } else {
                                        alert("An error has occured. Please try again");
                                    }

                                } catch (err) {
                                    setSubmitting(false);
                                    console.log(err);
                                    alert(`An error has occured (${err.message})`);
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="form-row">
                                        <div className="col-md form-group">
                                            <label htmlFor="authKey">Authorisation Key</label>
                                            <Field
                                                type="password"
                                                className="form-control"
                                                id="authKey"
                                                name="authKey"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md form-group">
                                            <label htmlFor="title">New Application Form Title</label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                name="title"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md form-group">
                                            <label htmlFor="header">New Application Form Header</label>
                                            <Field
                                                as="textarea"
                                                className="form-control"
                                                id="header"
                                                name="header"
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md form-group">
                                            <label htmlFor="footer">New Application Form Footer</label>
                                            <Field
                                                as="textarea"
                                                className="form-control"
                                                id="footer"
                                                name="footer"
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex align-items-center">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            Submit
                                        </button>
                                        {isSubmitting && (
                                            <div className="spinner-border ml-3" role="status" aria-hidden="true">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default AdminForm;